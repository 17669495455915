@tailwind base;
@tailwind components;
@tailwind utilities;

html {
	font-size: 14px; /* Adjust the root font-size for mobile devices */
}

.bg-gray-50 {
	background: linear-gradient(214.18deg, #c0cecb 9.75%, #cfdbdc 192.87%);
}

.diagnostic-search-user {
	margin: auto;
	margin-right: 30%;
}
.search_bar {
	height: 43px;
	background: url("assets/glass.svg") no-repeat scroll 7px 7px;
	padding-left: 40px;
	font-family: "Inter";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
}
.text-gray-700 {
	margin-left: 20px;
	margin-right: 20px;
	font-family: "Inter";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
}
.box-buttons {
	background: #ffffff;
	border-radius: 17px;
	box-shadow: 0px 4px 25px rgb(20 24 36 / 25%);
	height: 60px;
}

/*header login */
.email-input {
	width: 100%;
	padding: 0.3rem;
	outline: none;
	border: none;
	margin: auto;
	margin-top: 5px;
	background: #f0f4f5;
	border: 0.5px solid #9eadb6;
	border-radius: 5px;
}

.add-user {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 12px 30px;
	gap: 10px;

	width: 236px;
	height: 46px;

	/* Gradient */

	background: linear-gradient(38.96deg, #00d3aa -1.33%, #70f1fa 145.88%);
	border-radius: 9px;

	/* Inside auto layout */

	flex: none;
	order: 1;
	flex-grow: 0;
}

.add-user-p {
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 22px;
	/* identical to box height */

	text-align: center;

	/* White */

	color: #fafafa;
	background: linear-gradient(38.96deg, #00d3aa -1.33%, #70f1fa 145.88%);
	border-radius: 17px;
}

img {
	max-width: none;
}

input::placeholder {
	font-family: "Aeonik-Regular";
	color: #667085;
	font-weight: 500;
	font-size: 14px;
}
