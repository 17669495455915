@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'Open Sans';
  src: url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');
}


body {
  background-color: #E9ECF0;
}
/* on mobile body backround color is #121221 */

@media (max-width: 1280px) {
  body {
    background-color: #E9ECF0;
  }
}

html.disable-landscape {
  /* Rotate the content container */
  transform: rotate(-90deg);
  transform-origin: left top;
  /* Set content width to viewport height */
  width: 100vh;
  /* Set content height to viewport width */
  height: 100vw;
  overflow-x: hidden;
  position: absolute;
  top: 100%;
  left: 0;
}

.height-10-12-screen {
  height: calc(100vh * 11 / 12);
}

.height-8-12-screen {
  height: calc(100vh * 10 / 12);
}

.search-bar::-webkit-input-placeholder {
  font-size: 0.875rem; /* 14px */
  line-height: 1.25rem; /* 20px */
  font-family: 'Gilroy-SemiBold';
  color: "#888890";
}

.auth-modal input::-webkit-input-placeholder {
  font-size: 14px;
  line-height: 18px;
  font-family: 'Gilroy-Medium';
  color: #888890;
}

button.gradient-border-button {
  border: solid 2px transparent;
  background-image: linear-gradient(101deg, #9339A1, #6D3DAB);
  box-shadow: 2px 1000px 1px #282C32 inset;
}

.search-bar-mobile::-webkit-input-placeholder {
  font-size: 14px;
  line-height: 18px;
  font-family: 'Gilroy-Medium';
  color: #E3E3E3
}

.button.nav-outline {
  width: auto;
  color: #e67e54;
  background-color: #fff;
  border: 1px solid #e67e54;
  margin-left: 0;
}

.button.nav-plain {
  width: auto;
  margin-left: 8px;
}

.button {
  z-index: 10;
  width: auto;
  grid-column-gap: 12px;
  grid-row-gap: 0px;
  color: #fff;
  text-align: center;
  cursor: pointer;
  background-color: #e67e54;
  border: 1px solid #e67e54;
  border-radius: 4px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-decoration: none;
  transition: all .2s;
  display: flex;
  position: relative;
}

.w-button {
  color: #fff;
  line-height: inherit;
  cursor: pointer;
  background-color: #e67e54;
  border: 0;
  border-radius: 4px;
  padding: 9px 15px;
  text-decoration: none;
  display: inline-block;
}
.video-mask {
  width: 400px;
  border-radius: 10px;
  overflow: hidden;
  vertical-align: baseline;
  display: inline-block;
}

.height-8-12-screen {
  height: calc(100vh * 12 / 12);
}

.rdg-cell {
  font-size: 13px !important;
}

[role="columnheader"]:not(.headertable) {
  background-color: #D0D5DD !important;
  color: #667085 !important;
}

.headertable [role="columnheader"] {
  background-color: #F2F4F7 !important;
  color: #002F54 !important;
  font-style: bold !important;
  font-weight: 700 !important;
}

input[type="checkbox"] {
  display: none;
}


input[type="checkbox"]:checked + .checkbox-label::after {
  transform: translate(-50%, -50%) scale(1);
}



